import * as React from "react"
import Layout from "../components/layout"

// markup
const ThanksPage = () => {
  return (
    <Layout>
      <div className="flex-min-height-100vh text-color-black pos-rel container">
        <div className="center">
        <h1 className="headline-xxl padding-bottom-120">Thank You!</h1>
      <h2 className="subheading-l">We successfully received your form submission</h2>
      <p className="body-text-l">
        We're so glad you want to serve here at Amplified. Thank you for your willingness. Look forward to speaking with you soon!
      </p>
      </div>
    </div>
    </Layout>
  )
}

export default ThanksPage